import React from "react"

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h1 className="mt-3">Gift Exchange Generator Privacy Policy</h1>

          <p>
            We do not collect any of your information. No data is saved.
          </p>
        </div>
      </div>
    </div>
  )
}
